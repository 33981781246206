import crypto from 'crypto'
import { ASSET_SERVER_URL } from '@/lib/sharedConstants'

export function createUrl(
  filename: string,
  width: number,
  height?: number | undefined,
  strategy?: string | undefined,
): string {
  // Create image request object
  const imageRequest = {
    bucket: 'fitreisen-cdn-images',
    key: filename,
    edits: {
      resize: {
        width: width,
        height: height,
        fit: 'cover',
        position: strategy !== undefined ? strategy : 'center',
      },
    },
  }

  // Encode for URL
  const stringifiedObject = JSON.stringify(imageRequest)
  const encodedObject = Buffer.from(stringifiedObject).toString('base64')

  // Create path for URL
  const path = '/' + encodedObject

  // Calculate signature based on path
  const secret = 'MuY7YgrzVXTq3sff'
  const signature = crypto.createHmac('sha256', secret).update(path).digest('hex')

  // Return URL
  return `${ASSET_SERVER_URL}${path}?signature=${signature}`
}
