'use client'

import { ReactElement } from 'react'
import _flatten from 'lodash/flatten'
import { Sizes } from '@/components/common/picture/Sizes'
import { PictureClientComponent } from '@/components/common/picture/PictureClientComponent'
import { createUrl } from '@/lib/utils/create-url'
import { Source, SourceSetEntry } from '@/components/common/picture/Source'

const fullWidthRange: [number, number] = [1280, 3840]
const fullWidthSources = 6

const sizeDefinitions: Record<Sizes, [number, number]> = {
  [Sizes.xs]: [320, 639],
  [Sizes.sm]: [640, 767],
  [Sizes.md]: [768, 1023],
  [Sizes.lg]: [1024, 1279],
  [Sizes.xl]: [1720, 3840],
}

const numberOfStepsByScreenSize: Record<Sizes, number> = {
  [Sizes.xs]: 8,
  [Sizes.sm]: 3,
  [Sizes.md]: 3,
  [Sizes.lg]: 2,
  [Sizes.xl]: 2,
}

export const VIEW_WIDTH_SIZES: PictureSizeDefinition = {
  [Sizes.xs]: [319, 639],
  [Sizes.sm]: [640, 767],
  [Sizes.md]: [768, 1023],
  [Sizes.lg]: [1024, 1279],
  [Sizes.xl]: [1280, 1280],
}

type ExactPictureSizeDefintion = [number, number]

interface PictureProps {
  onImageLoad?: () => void
  cornerRounding?: string
  staticPosition?: boolean
  src: string
  additionalClassNames?: string
  alt: string
  title?: string
  size: PictureSizeDefinition | ExactPictureSizeDefintion
  priority?: boolean
  fullWidth?: boolean
  // onClick?: () => void
  openPageURL?: string
  openInNewWindow?: boolean
  hidden?: boolean
  standardSize?: boolean
}

type PictureSizeEntry =
  | [number, number]
  | [number, number, number]
  | [number, number, [number, number]]

export type PictureSizeDefinition = Record<Sizes, PictureSizeEntry>

export function sources(
  src: string,
  size: PictureSizeDefinition,
  fullWidth: boolean,
): SourceSetEntry[] {
  function process(
    measures: PictureSizeEntry,
    sizeRange: [number, number],
    numberOfSteps: number,
  ): SourceSetEntry[] {
    if (measures.length === 3) {
      const stepSizeScreenWidth = Math.floor((sizeRange[1] - (sizeRange[0] || 320)) / numberOfSteps)
      const stepSizeImageWidth = Math.floor((measures[1] - measures[0]) / numberOfSteps)
      const stepSizeImageHeight = Array.isArray(measures[2])
        ? Math.floor((measures[2][1] - measures[2][0]) / numberOfSteps)
        : 0

      const entries: SourceSetEntry[] = []

      for (let i = 0; i < numberOfSteps - 1; i++) {
        const minRangeOffset = i === 0 ? 0 : 1
        entries.push(
          getEntry(
            src,
            sizeRange[0] + stepSizeScreenWidth * i + minRangeOffset,
            sizeRange[0] + stepSizeScreenWidth * (i + 1),
            measures[0] + stepSizeImageWidth * (i + 1),
            Array.isArray(measures[2])
              ? measures[2][0] + stepSizeImageHeight * (i + 1)
              : measures[2],
          ),
        )
      }
      entries.push(
        getEntry(
          src,
          sizeRange[0] + stepSizeScreenWidth * (numberOfSteps - 1) + 1,
          sizeRange[1],
          measures[1],
          Array.isArray(measures[2]) ? measures[2][1] : measures[2],
        ),
      )

      return entries
    }

    return []
  }

  function processSize([size, measures]: [
    size: string,
    measure: PictureSizeEntry,
  ]): SourceSetEntry[] {
    if (size === Sizes.xl) {
      return []
    }

    return process(
      measures,
      sizeDefinitions[size as Sizes],
      numberOfStepsByScreenSize[size as Sizes],
    )
  }

  function getHeight(entry: PictureSizeEntry): number | undefined {
    if (entry.length === 3) {
      return Array.isArray(entry[2]) ? entry[2][1] : entry[2]
    }
  }

  return [
    getEntryWithWithMediaQuery(
      src,
      `(max-width: 319px)`,
      size[Sizes.xs][0],
      getHeight(size[Sizes.xs]),
    ),
    ..._flatten(Object.entries(size).map(processSize)),
    ...(fullWidth
      ? process(
          [fullWidthRange[0], fullWidthRange[1], size[Sizes.xl][2] as number],
          fullWidthRange,
          fullWidthSources,
        )
      : []),
    getEntryWithWithMediaQuery(
      src,
      `(min-width: ${fullWidth ? fullWidthRange[1] + 1 : sizeDefinitions[Sizes.lg][1] + 1}px)`,
      size[Sizes.xl][1],
      Array.isArray(size[Sizes.xl][2]) ? size[Sizes.xl][2][1] : size[Sizes.xl][2],
    ),
  ]
}

function getEntry(
  src: string,
  minRange: number,
  maxRange: number,
  width: number,
  height: number,
): SourceSetEntry {
  return getEntryWithWithMediaQuery(
    src,
    `(min-width: ${minRange}px) and (max-width: ${maxRange}px)`,
    width,
    height,
  )
}

function getEntryWithWithMediaQuery(
  src: string,
  mediaQuery: string,
  width: number,
  height?: number,
): SourceSetEntry {
  return {
    media: mediaQuery,
    srcSet: srcSet(
      src,
      Math.floor(width),
      typeof height === 'number' ? Math.floor(height) : undefined,
    ),
  }
}

function srcSet(src: string, width: number, height: number | undefined): string {
  const filename = src.split('/')[0]

  function multiply(value: number | undefined, factor: number): number | undefined {
    if (typeof value === 'number') {
      return Math.round(value * factor)
    }
  }

  return `${createUrl(filename, width, height)}, ${createUrl(
    filename,
    Math.round(width * 1.5),
    multiply(height, 1.5),
  )} 1.5x, ${createUrl(filename, width * 2, multiply(height, 2))} 2x, ${createUrl(
    filename,
    width * 3,
    multiply(height, 3),
  )} 3x, ${createUrl(filename, width * 4, multiply(height, 4))} 4x`
}
function isPictureSizeDefinition(size: PictureSizeDefinition | ExactPictureSizeDefintion): boolean {
  return !Array.isArray(size)
}

function getSourcesList(props: PictureProps): SourceSetEntry[] {
  return isPictureSizeDefinition(props.size)
    ? sources(props.src, props.size as PictureSizeDefinition, props.fullWidth || false)
    : []
}

function getImageSource(sourcesList: SourceSetEntry[], props: PictureProps): string {
  if (isPictureSizeDefinition(props.size)) {
    return sourcesList[sourcesList.length - 1].srcSet.split(',')[0]
  } else {
    const sizes = props.size as ExactPictureSizeDefintion
    return createUrl(props.src, sizes[0], sizes[1])
  }
}

export function Picture(props: PictureProps): ReactElement | null {
  if (!props.src) {
    return null
  }

  const sourcesList = getSourcesList(props)
  const imageSource = getImageSource(sourcesList, props)
  // sourcesList.map((source) => source.srcSet).join(', ')

  return (
    <PictureClientComponent
      hidden={props.hidden}
      onImageLoad={props.onImageLoad}
      src={imageSource}
      sourceSetEntries={sourcesList.map((source, index) => (
        <Source key={source.srcSet + index.toString()} source={source} />
      ))}
      title={props.title}
      alt={props.alt}
      additionalClassNames={props.additionalClassNames}
      cornerRounding={props.cornerRounding}
      staticPosition={props.staticPosition}
      priority={props.priority}
      openPageURL={props.openPageURL}
      openInNewWindow={props.openInNewWindow}
      standardSize={props.standardSize}
    />
  )
}
