'use client'

import { ReactElement, ReactNode, useContext, useEffect, useRef, useState } from 'react'
import { ConfigContext } from '@/components/common/ConfigContext'
import classNames from 'classnames'
import { LinkTarget } from '@/types/Page'

export function PictureClientComponent(props: {
  openPageURL?: string
  openInNewWindow?: boolean
  src: string
  sourceSetEntries: ReactNode[]
  title: string | undefined
  alt: string
  additionalClassNames: string | undefined
  staticPosition: undefined | boolean
  priority: undefined | boolean
  cornerRounding?: string
  onImageLoad?: () => void
  hidden?: boolean
  standardSize?: boolean
}): ReactElement {
  const eagerLoadImages = useContext(ConfigContext)!.eagerLoadImages
  const placeholderRef = useRef<HTMLDivElement>(null)
  const [isIntersecting, setIsIntersecting] = useState(props.priority || eagerLoadImages)
  const imageRef = useRef<HTMLImageElement>(null)

  function openPage(): void {
    if (!props.openPageURL) {
      return
    }

    if (props.openInNewWindow) {
      window.open(props.openPageURL, LinkTarget.BLANK)
    } else {
      window.location.href = props.openPageURL
    }
  }

  useEffect(() => {
    if (!('IntersectionObserver' in window)) {
      setIsIntersecting(true)
      return
    }

    let observer: IntersectionObserver

    if (placeholderRef.current) {
      const topPosition = placeholderRef.current.getBoundingClientRect().top

      if (topPosition < window.innerHeight) {
        setIsIntersecting(true)
        return
      }

      if (!isIntersecting) {
        observer = new IntersectionObserver(
          ([entry]) => {
            // Set state to the entry's isIntersecting property
            const intersecting = isIntersecting || entry.isIntersecting
            setIsIntersecting(intersecting)

            if (intersecting) {
              observer.disconnect()
            }
          },
          {
            rootMargin: '0px 0px 400px 0px', // Load images slightly before they come into view
          },
        )
        observer.observe(placeholderRef.current)
      }
    }

    // Clean up
    return () => observer && observer.disconnect()
  }, [placeholderRef.current, isIntersecting])

  useEffect(() => {
    if (props.onImageLoad && imageRef.current && imageRef.current.complete) {
      props.onImageLoad()
    }
  }, [imageRef.current])

  return isIntersecting ? (
    <picture>
      {props.sourceSetEntries}
      <img
        ref={imageRef}
        onLoad={props.onImageLoad}
        title={props.title}
        decoding="async"
        alt={props.alt}
        className={classNames(
          props.additionalClassNames,
          'inset-0 overflow-hidden object-cover',
          props.cornerRounding || 'rounded-md',
          {
            'h-full w-full': !props.standardSize,
            absolute: !props.staticPosition,
            invisible: props.hidden,
          },
        )}
        src={props.src}
        loading={props.priority ? 'eager' : 'lazy'}
        fetchPriority={props.priority ? 'high' : undefined}
        onClick={openPage}
      />
    </picture>
  ) : (
    <div className="h-full w-full" ref={placeholderRef} />
  )
}
