'use client'

import React, { ReactElement, useContext, useEffect, useState } from 'react'
import { loadLikedHotels, storeLikedHotels } from '@/lib/utils/utils'
import { ConfigContext } from '@/components/common/ConfigContext'
import { useIsWhiteLabel } from '@/lib/hooks'
import classNames from 'classnames'
import { trackUserAction } from '@/lib/frontent-api'
import { TrackingDataEventTypes } from '@/types/Misc'

interface LikedHotelButtonProps {
  greyBackground?: boolean
  size?: string
  hotelCode: string
  colorUnselected: string
  strokeUnselected: string
  strokeSelected: string
  additionalClassName?: string
}

export function LikeHotelButton(props: LikedHotelButtonProps): ReactElement | null {
  const [likedHotels, setLikedHotels] = useState<string[]>(loadLikedHotels())
  const config = useContext(ConfigContext)!
  const whitelabel = useIsWhiteLabel()
  const [loadedOnClientSide, setLoadedOnClientSide] = useState(false)

  useEffect(() => setLoadedOnClientSide(true), [])

  useEffect(() => {
    function handleStorageEvent(): void {
      setLikedHotels(loadLikedHotels())
    }

    handleStorageEvent()

    window.addEventListener('storage', handleStorageEvent)

    return () => window.removeEventListener('storage', handleStorageEvent)
  }, [])

  function updateLikedHotels(updatedLikedHotels: string[]): void {
    setLikedHotels(updatedLikedHotels)
    storeLikedHotels(updatedLikedHotels)
  }

  function hasBeenLiked(): boolean {
    return loadedOnClientSide && likedHotels?.includes(props.hotelCode)
  }

  function toggleLikedHotel(): void {
    if (loadLikedHotels().includes(props.hotelCode)) {
      updateLikedHotels(loadLikedHotels().filter((hc) => hc !== props.hotelCode))
    } else {
      updateLikedHotels([...loadLikedHotels(), props.hotelCode])
      trackUserAction(config.domain, { hotelCode: props.hotelCode, type: TrackingDataEventTypes.LIKE })
    }
  }

  if (whitelabel) {
    return null
  }

  return (
    <div
      className={classNames(
        'no-tap-highlight z-30 h-8 w-8 cursor-pointer',
        props.additionalClassName,
      )}
      onClick={toggleLikedHotel}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className={classNames(
          'stoke-2 ease-in',
          {
            'fill-primary': hasBeenLiked(),
            'fill-black/40': !hasBeenLiked() && props.greyBackground,
            'fill-[#F9F9F9]': !hasBeenLiked() && !props.greyBackground,
          },
          props.size || 'h-8 w-8',
        )}
        viewBox="0 0 24 24"
        stroke={hasBeenLiked() ? props.strokeSelected : props.strokeUnselected}
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          className="stroke-1"
          d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z"
        />
      </svg>
    </div>
  )
}
